@import '_variables';

.App {
  padding: 8px;
  max-width: 800px;
  margin: auto;

  @media print {
    .printable,
    .printable * {
      display: block;
    }
  }
  @media screen {
    .printable *{
      display: none;
    }
  }
  .routerLink {
    padding: 8px;
    // border-radius: $borderRadius;
    border-bottom: 1px solid transparent;
    &:hover {
      border-bottom: 1px solid lighten($secondary, 20%);
    }
    transition: all 0.5s ease-in;
    &.active {
      border-bottom: 1px solid lighten($success, 20%);
    }
  }
  .xpbox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .avatar {
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 5px 5px 8px 1px #00000099;
  }
  .button {
    text-decoration: none;
    padding: 8px;
    color: $secondary;
    border: 1px solid $secondary;
    border-radius: $borderRadius;
    &:hover {
      background: $secondary;
      color: white;
    }
    &:active {
      opacity: 0.5;
    }
    transition: all 0.2s ease-in-out;
  }
}

.navbar,
.footer {
  ul {
    list-style: none;
    display: flex;
    padding-left: 0;
    flex-wrap: wrap;
    li {
      margin: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:first-child {
        margin-right: 32px;
        font-family: 'Lobster', cursive;
        font-size: larger;
      }
    }
  }
}

.navbar {
  background-color: #ffffff;
  position: sticky;
  top: 0;
}
