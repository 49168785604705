// /* CSS HEX */
// --primary: #32292fff;
// --secondary: #ef2d56ff;
// --info: #05668dff;
// --warning: #8cd867ff;
// --success: #2fbf71ff;

// /* CSS HSL */
// --primary: hsla(320, 10%, 18%, 1);
// --secondary: hsla(347, 86%, 56%, 1);
// --info: hsla(197, 93%, 29%, 1);
// --warning: hsla(100, 59%, 63%, 1);
// --success: hsla(147, 61%, 47%, 1);

/* SCSS HEX */
$primary: #32292fff;
$secondary: #ef2d56ff;
$info: #05668dff;
$warning: #8cd867ff;
$success: #2fbf71ff;

$borderRadius: 4px;

// /* SCSS HSL */
// $primary: hsla(320, 10%, 18%, 1);
// $secondary: hsla(347, 86%, 56%, 1);
// $info: hsla(197, 93%, 29%, 1);
// $warning: hsla(100, 59%, 63%, 1);
// $success: hsla(147, 61%, 47%, 1);

// /* SCSS RGB */
// $primary: rgba(50, 41, 47, 1);
// $secondary: rgba(239, 45, 86, 1);
// $info: rgba(5, 102, 141, 1);
// $warning: rgba(140, 216, 103, 1);
// $success: rgba(47, 191, 113, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(
  0deg,
  #32292fff,
  #ef2d56ff,
  #05668dff,
  #8cd867ff,
  #2fbf71ff
);
$gradient-right: linear-gradient(
  90deg,
  #32292fff,
  #ef2d56ff,
  #05668dff,
  #8cd867ff,
  #2fbf71ff
);
$gradient-bottom: linear-gradient(
  180deg,
  #32292fff,
  #ef2d56ff,
  #05668dff,
  #8cd867ff,
  #2fbf71ff
);
$gradient-left: linear-gradient(
  270deg,
  #32292fff,
  #ef2d56ff,
  #05668dff,
  #8cd867ff,
  #2fbf71ff
);
$gradient-top-right: linear-gradient(
  45deg,
  #32292fff,
  #ef2d56ff,
  #05668dff,
  #8cd867ff,
  #2fbf71ff
);
$gradient-bottom-right: linear-gradient(
  135deg,
  #32292fff,
  #ef2d56ff,
  #05668dff,
  #8cd867ff,
  #2fbf71ff
);
$gradient-top-left: linear-gradient(
  225deg,
  #32292fff,
  #ef2d56ff,
  #05668dff,
  #8cd867ff,
  #2fbf71ff
);
$gradient-bottom-left: linear-gradient(
  315deg,
  #32292fff,
  #ef2d56ff,
  #05668dff,
  #8cd867ff,
  #2fbf71ff
);
$gradient-radial: radial-gradient(
  #32292fff,
  #ef2d56ff,
  #05668dff,
  #8cd867ff,
  #2fbf71ff
);
